<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  metaInfo: {
    titleTemplate: '%s - Quralo Analytics',
    title: 'Quralo Analytics'
  },

  watch: {
    '$store.getters.settingsLanguage' (newLang) {
      this.$i18n.locale = newLang
      this.$vuetify.lang.current = newLang
    }
  },

  beforeCreate () {
    this.$i18n.locale = this.$store.getters.settingsLanguage
    this.$vuetify.lang.current = this.$store.getters.settingsLanguage
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --font-open-sans: 'Open Sans', sans-serif;
  --font-poppins: 'Poppins', sans-serif;

  --font-family-primary: 'Inter', sans-serif;
  --font-family-secondary: 'Poppins', sans-serif;
}

$body-font-family: 'Open Sans', sans-serif;
$title-font: 'Poppins', sans-serif;

$font-open-sans: 'Open Sans', sans-serif;
$font-poppins: 'Poppins', sans-serif;

</style>

import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import store from '../store'

// Translation provided by Vuetify (javascript)
import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'

import '@fortawesome/fontawesome-pro/css/all.min.css'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { es, en },
    current: store.getters.settingsLanguage
  },
  iconfont: 'fa',
  theme: {
    primary: '#4172f2',
    secondary: '#292d34',
    accent: '#444444',
    error: '#ef5350',
    info: '#80cbc4',
    success: '#66bb6a',
    warning: '#ffab40'
  }
})

/*
 * Module to handle all the user
 */

import localStorageService from '@/services/localStorage'

// Local store keys dictionary
const LS_KEYS = {
  USER_SESSION: 'user:session',
  USER_SETTINGS: 'user:settings'
}

export default {

  state: {
    session: null,
    settings: {
      lang: 'es'
    }
  },

  mutations: {
    setSession (state, sessionData) {
      state.session = sessionData
    },

    setUserSettings (state, settings) {
      state.settings = settings
    },

    setUserLanguage (state, lang) {
      state.settings.lang = lang
      localStorageService.setKey(LS_KEYS.USER_SETTINGS, state.settings)
    }
  },

  actions: {

    initializeUser ({ dispatch }) {
      dispatch('loadActiveSession')
      dispatch('loadUserSettings')
    },

    loadUserSettings ({ state, commit }) {
      // Load user's setting from local store
      if (localStorageService.hasKey(LS_KEYS.USER_SETTINGS)) {
        const settings = { ...state.settings, ...localStorageService.getKey(LS_KEYS.USER_SETTINGS) }
        commit('setUserSettings', settings)
        return
      }

      localStorageService.setKey(LS_KEYS.USER_SETTINGS, state.settings)
    },

    loadActiveSession ({ commit }) {
      // Load user's crop areas from local store
      if (localStorageService.hasKey(LS_KEYS.USER_SESSION)) {
        commit('setSession', localStorageService.getKey(LS_KEYS.USER_SESSION))
      }
    },

    setActiveSession ({ commit }, sessionData) {
      commit('setSession', sessionData)
      localStorageService.setKey(LS_KEYS.USER_SESSION, sessionData)
    },

    logoutActiveSession ({ commit }) {
      localStorageService.removeKey(LS_KEYS.USER_SESSION)
      commit('setSession', null)
      window.location.href = '/app'
    }
  },

  getters: {
    sessionIsActive: state => {
      return state.session !== null
    },

    settingsLanguage: state => {
      return state.settings.lang
    },

    userFullName: state => {
      if (!state.session) return ''

      return `${state.session.firstname} ${state.session.lastname}`
    },

    username: state => {
      return state.session.user
    }
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'

Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(VueI18n)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

// ApexChart options
window.Apex.chart = {
  fontFamily: 'Open Sans, sans-serif'
}

store.dispatch('initialize')

new Vue({
  router,
  store,
  vuetify,
  i18n: {
    locale: store.getters.settingsLanguage
  },
  render: h => h(App)
}).$mount('#app')

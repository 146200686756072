import Vue from 'vue'
import Vuex from 'vuex'

import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initialized: false
  },

  getters: {
  },

  mutations: {
  },

  actions: {

    async initialize ({ dispatch, rootState }) {
      if (rootState.initialized) {
        return
      }

      // Load user and its account
      await dispatch('initializeUser')

      rootState.initialized = true
    }
  },
  modules: {
    user
  }
})

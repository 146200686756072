import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/LoginView.vue'),
    beforeEnter: (to, from, next) => {
      // Check if there is an active session
      if (store.getters.sessionIsActive) {
        next('/')
        return
      }

      next()
    }
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    redirect: { name: 'dashboard.home' },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/home',
        name: 'dashboard.home',
        component: () => import(
          /* webpackChunkName: "dashboard" */
          '../views/home/index.vue'
        )
      },
      {
        path: '/reports',
        name: 'dashboard.reports',
        component: () => import(
          /* webpackChunkName: "dashboard" */
          '../views/reports/index.vue'
        ),
        redirect: { name: 'dashboard.reports.demography' },
        children: [
          {
            path: '',
            redirect: 'dashboard.reports.demography'
          },
          {
            path: '/reports/demography',
            name: 'dashboard.reports.demography',
            component: () => import(
              /* webpackChunkName: "dashboard" */
              '../views/reports/DemographyView.vue'
            )
          },
          {
            path: '/reports/family-history',
            name: 'dashboard.reports.family_history',
            component: () => import(
              /* webpackChunkName: "dashboard" */
              '../views/reports/FamilyHistoryView.vue'
            )
          },
          {
            path: '/reports/personal-history',
            name: 'dashboard.reports.personal_history',
            component: () => import(
              /* webpackChunkName: "dashboard" */
              '../views/reports/PersonalHistoryView.vue'
            )
          },
          {
            path: '/reports/lifestyle',
            name: 'dashboard.reports.lifestyle',
            component: () => import(
              /* webpackChunkName: "dashboard" */
              '../views/reports/LifestyleView.vue'
            )
          },
          {
            path: '/reports/healthcare',
            name: 'dashboard.reports.healthcare',
            component: () => import(
              /* webpackChunkName: "dashboard" */
              '../views/reports/HealthcareAccessView.vue'
            )
          }
        ]
      }
      // {
      //   path: '/app/settings',
      //   name: 'settings',
      //   component: () => import(/* webpackChunkName: "dashboard" */ '../views/settings/index.vue')
      // }
    ]
  },
  {
    path: '*',
    name: 'Wildcard',
    beforeEnter: (to, from, next) => {
      next({ name: 'dashboard.home' })
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // Always scroll to top
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    console.log(store.getters.sessionIsActive)
    if (!store.getters.sessionIsActive) {
      next({ name: 'login' })
      return
    }

    next()
  } else {
    next()
  }
})

export default router

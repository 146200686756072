
const hasKey = function (key) {
  const data = localStorage.getItem(key)
  return data !== '' && typeof data !== 'undefined' && data !== null
}

const getKey = function (key) {
  if (hasKey) {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      localStorage.removeItem(key)
    }
  }
}

const setKey = function (key, data) {
  localStorage.setItem(key, JSON.stringify(data))
}

const removeKey = function (key) {
  localStorage.removeItem(key)
}

export default {
  hasKey,
  getKey,
  setKey,
  removeKey
}
